.App {
    /*width: 1629px;*/
    /*height: 917px;*/
    /*background: url("/images/zendesk.png") 0 0 no-repeat;*/
    /*background-size: 100%;*/
    position: relative;
}

.App .loading {
    position: absolute;
    width: 100%;
    height: 4px;
}

.AppCircularProgress {
    display: flex;
    padding: 2em;
    justify-content: center;
}
