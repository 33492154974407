.AppNavBar {
    flex-grow: 1
}

.AppNavBar .Title {
    flex-grow: 1;
    text-align: left;
}

.AppNavBar .Title > a,
.AppNavBar .Title > a:link,
.AppNavBar .Title > a:visited,
.AppNavBar .Title > a:hover {
    text-decoration: none;
    color: inherit;
}