.AppBreadcrumbsRoot {
    padding: 0.5rem 1rem;
}

.AppBreadcrumbsLink {
    display: flex;
}
.AppBreadcrumbsIcon {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
}

.AppBreadcrumbsRoot a,
.AppBreadcrumbsRoot span {
    display: flex;
}

.AppBreadcrumbsRoot a:link,
.AppBreadcrumbsRoot a:visited {
    color: inherit;
    text-decoration: none;
}

.AppBreadcrumbsRoot a:hover {
    color: inherit;
    text-decoration: underline;
}