.JobsList {
    width: 100%;
    overflow: scroll;
}

.JobsList .SameId {
    color: #999;
    font-size: 80%;
}

.JobsList .JobPrice {
    font-weight: bold;
}

.JobsList .JobPrice .PaymentVerified {
    color: green;
}

.JobsList .date {
    white-space: nowrap;
}

.JobsList .stuck .date {
    color: white;
    background: red;
}

.JobsList .JobStatus.Pending { color: #999; }
.JobsList .JobStatus.PaymentFailed { color: red; opacity: .3; }
.JobsList .JobStatus.Building { color: #999; }
.JobsList .JobStatus.BuildFailed { color: red; opacity: .3; }
.JobsList .JobStatus.New {}
.JobsList .JobStatus.Completed { color:green; }
.JobsList .JobStatus.Cancelled {}

.JobsList .LoadMore {
    padding: 1em;
    display: flex;
    justify-content: center;
}

.JobsList .Buttons {
    display: flex;
    justify-content: flex-end;
}

.selected {
    box-shadow: 0 3px 10px rgba(0,0,0,.1);
}
